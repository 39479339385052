import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c('div',{staticClass:"text-h4 mb-4"},[_vm._v("Workspace Admin")]),_c(VRow,{staticClass:"d-flex align-center"},[_c(VCol,{attrs:{"cols":"10"}},[_c('SearchPeople',{ref:"sPeople",attrs:{"label":"Search people","show-loader":false,"users-only":"","ldap-populate":"","dense":"","rounded":"","outlined":""},model:{value:(_vm.foundUser),callback:function ($$v) {_vm.foundUser=$$v},expression:"foundUser"}})],1),_c(VCol,{staticClass:"p-0 m-0"},[_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":_vm.search}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)],1)],1),(_vm.loading)?_c('div',[_c(VRow,{staticClass:"fill-height text-center",staticStyle:{"height":"200px"},attrs:{"align-content":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h6 font-weight-light"},[_vm._v("Loading...")]),_c(VProgressLinear,{attrs:{"indeterminate":"","rounded":"","height":"3"}})],1)],1)],1):(Object.keys(_vm.workspaces).length > 0)?_c('div',{staticClass:"mt-4"},[_c(VRow,_vm._l((_vm.workspaces),function(curWorkspace,id){return _c(VCard,{key:id,staticClass:"mx-2 my-2 mx-auto",attrs:{"elevation":"2","outlined":"","width":"350","loading":_vm.workspaceLoading.includes(curWorkspace.id)}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.getWorkspaceName(curWorkspace))+" "),_c(VIcon,{staticClass:"ml-2",attrs:{"small":"","color":_vm.getStatusColor(curWorkspace)}},[_vm._v("mdi-checkbox-blank-circle")]),_c(VRow,{attrs:{"align":"center","justify":"end"}},[_c(VBtn,{staticClass:"ml-2 ",attrs:{"icon":""},on:{"click":function($event){return _vm.refreshWorkspace(curWorkspace)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1),(curWorkspace.client_data_address || curWorkspace.IpAddress)?_c(VMenu,{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.rebootWorkspace(curWorkspace)}}},[_c(VListItemTitle,[_vm._v("Reboot Workspace")])],1),_c(VListItem,{on:{"click":function($event){return _vm.restoreWorkspace(curWorkspace)}}},[_c(VListItemTitle,[_vm._v("Restore Workspace")])],1)],1)],1):_vm._e()],1)],1),_c(VCardText,[_c('div',[_vm._v("User: "+_vm._s(curWorkspace.UserName))]),(curWorkspace.desktop_pool_or_farm_name)?_c('div',[_vm._v("Pool: "+_vm._s(curWorkspace.desktop_pool_or_farm_name))]):_vm._e(),(curWorkspace.session_state)?_c('div',[_vm._v("Status: "+_vm._s(curWorkspace.session_state))]):_c('div',[_vm._v("Status: "+_vm._s(curWorkspace.State)+" ")]),(curWorkspace.IpAddress)?_c('div',[_vm._v("IP: "+_vm._s(curWorkspace.IpAddress))]):(curWorkspace.client_data_address)?_c('div',[_vm._v("IP: "+_vm._s(curWorkspace.client_data_address))]):_vm._e(),(curWorkspace.WorkspaceProperties)?_c('div',[_vm._v("Compute Type: "+_vm._s(curWorkspace.WorkspaceProperties.ComputeTypeName))]):_vm._e()]),_c(VCardActions,[_c(VSpacer),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.removeWorkspace(curWorkspace)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)],1)}),1)],1):_vm._e()],1),_c('ConfirmDialog',{ref:"confirmDlg",attrs:{"title":_vm.confirmDlgText,"confirm-button-text":"Confirm","confirm-button-icon":"mdi-check"},on:{"confirm":function($event){return _vm.confirmAction()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }